import React from "react";
import logo from '../assets/lemon.svg'
import fruitbox from '../assets/fruitbox.svg'
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import Modal from "./Modal";

export class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            show: false
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    render() {
        return (
            <div id="mainBody">
                <div id="headerrow">
                    <Container>
                        <Row >
                            <Col>
                                <br />
                                <h1>Not all ugly produce are "lemons"</h1>
                                <br />
                                <h4>Delivering fresh "ugly" produce direct from farmers to you. Cheaper. <br /> Fresher.</h4>
                                <br></br>
                                <div classname="row justify-content-center">
                                    <Modal show={this.state.show} handleClose={this.hideModal}>

                                    </Modal>
                                    <button onClick={this.showModal} className="button">GET EARLY ACCESS</button>
                                </div>
                            </Col>
                            <Col className="d-none d-lg-block text-center">
                                <img src={fruitbox} alt="Fruit Box" id="fruitbox"></img>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div id="howitworks">
                    <Container>
                        <Row >
                            <center><h1>How it works?</h1></center>
                            <Col xs={12} md={4}>
                                <Card>
                                    <Card.Img variant="top" src={logo} className="d-none d-lg-block text-center" />
                                    <Card.Body>
                                        <Card.Title>Sign up and select the amount of produce you need for the week</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={4}>
                                <Card>
                                    <Card.Img variant="top" src={logo} className="d-none d-lg-block text-center" />
                                    <Card.Body>
                                        <Card.Title>Weekly deliveries of curated seasonal produce</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={4}>
                                <Card>
                                    <Card.Img variant="top" src={logo} className="d-none d-lg-block text-center" />
                                    <Card.Body>
                                        <Card.Title>Enjoy fresh produce at a fraction of the price</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
                <div id="impact">
                    <Container>
                        <Row>
                            <center><h1>Play your part today!</h1></center>
                            <Col xs={12} md={6}>

                                <h3>Reduce food waste</h3>

                                <p>You're helping by consuming produce that would otherwise go to the landfill.</p>
                            </Col>
                            <Col xs={12} md={6}>
                                <h3>Supporting local farmers</h3>
                                <p>We give back to local farmers by buying produce that they otherwise can't sell.</p>
                            </Col>
                            <Col xs={12} md={6}>
                                <h3>Value for money</h3>
                                <p>In return you get high quality seasonal produce for fraction of the price.</p>
                            </Col>
                            <Col xs={12} md={6}>
                                <h3>Giving back to the community</h3>
                                <p>Any excess produce that we have will be donated to local foodbanks</p>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
        );
    }
}