import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDJHjwyWHpRnSzqbOdYyTMwnP6v8q4Dt3U",
    authDomain: "lemon-box-a03c3.firebaseapp.com",
    projectId: "lemon-box-a03c3",
    storageBucket: "lemon-box-a03c3.appspot.com",
    messagingSenderId: "300344378443",
    appId: "1:300344378443:web:2740278e430789cb3b39ce",
    measurementId: "G-SN7HF5P4J0"
};



// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();

export default db;