import React from 'react'
import { Navbar } from 'react-bootstrap'
import { Nav } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import logo from "../assets/lemon.svg"
import navbar from '../assets/navbar.svg'

export class Header extends React.Component {
  render() {
    return [
      <Navbar collapseOnSelect expand="lg">
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt=""
              src={logo}
              width="50"
              height="50"
            />{' '}
            Lemon Box
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav justify>
              <Nav.Link href="#signup">SIGN UP</Nav.Link>
              <Nav.Link href="#howitworks">HOW IT WORKS</Nav.Link>
              <Nav.Link href="#impact">IMPACT</Nav.Link>
            </Nav>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
    ];
  }
}