import './App.css';
import { Home } from './components/Home';
import { Header } from './components/Header';
import React from 'react';

function App() {
  return (
    [
      <Header />,
      <Home />
    ]
  );
}

export default App;
