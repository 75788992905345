import "../Modal.css";
import { useState } from "react";
import db from "../firebase.config";
import { Form } from "react-bootstrap";
import { Container } from "react-bootstrap";

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  
  const submit = (e) => {
    e.preventDefault();
    db.collection("email").add({
      name: customerName,
      email: customerEmail,
      timestamp: Date().toLocaleString()
    });
  
    setCustomerName("");
    setCustomerEmail("");
    handleClose();
    document.getElementById("signupForm").reset();
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <a className="close" onClick={handleClose}></a>
        <Container>
          <Form className="modalForm" id="signupForm">
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Control type="text" onChange={(e) => setCustomerName(e.target.value)} placeholder="Name" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control type="email" onChange={(e) => setCustomerEmail(e.target.value)} placeholder="Enter email" />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>
            <button className="button" id="submitButton" onClick={submit}>
              Sign Up
            </button>
          </Form>
        </Container>
        {children}
      </section>
    </div>
  );
};

export default Modal;